import { useState, useEffect } from "react";
import Home from "./home";

function App() {

  useEffect(() => {

    (function (c, l, a, r, i, t, y) {
      c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
      t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
      y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
    })(window, document, "clarity", "script", "m5kpw16qri");
  }, []);

  return (
    <div className="">
      <Home/>
    </div>
  );
}

export default App;
